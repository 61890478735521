import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useLocalStorage } from '../useLocalStorage';
import { Icons } from './Icons';
import { track } from '../utils/analytics';
import classes from './TopBanner.module.css';

const TOP_BANNER_ID = 'survey-2025';
const GAME_NAME = 'contexto';
const SURVEY_LINKS = {
  contexto: {
    pt: 'https://forms.gle/gfaYmnZBZbDHf7R36',
    en: 'https://forms.gle/rYvrrcpbPRS4mLz38',
    es: 'https://forms.gle/vBZwkWsjLon63qFu6',
  },
  conexo: {
    pt: 'https://forms.gle/X3FnEA3HXfwzKcDf8',
    en: 'https://forms.gle/aesBdhUSkkkKTktw9',
    es: 'https://forms.gle/9h77vDMLwmDWdhFk7',
  },
  letroso: {
    pt: 'https://forms.gle/4twbCnzXDBEgunaf8',
    en: 'https://forms.gle/eragfz2L6K2VRy8t7',
    es: 'https://forms.gle/5bH4WR9pDnXakUN66',
  },
};
const START_DATE = '2025-02-16';
const END_DATE = '2025-03-02';

function TopBanner({ onActionClick = () => {}, language }) {
  const { t } = useTranslation();
  const [dismissed, setDismissed] = useLocalStorage(
    `${GAME_NAME}-top-banner-dismissed`,
    ''
  );

  const ignoreDismissed = false;
  const startDate = dayjs(START_DATE).startOf('day');
  const endDate = dayjs(END_DATE).endOf('day');

  const showBanner =
    startDate.isBefore(dayjs()) &&
    endDate.isAfter(dayjs()) &&
    (ignoreDismissed || dismissed !== TOP_BANNER_ID);

  const handleActionClick = () => {
    track.topBannerAction(language, TOP_BANNER_ID);
    onActionClick();
  };

  const handleCloseClick = () => {
    track.topBannerDismiss(language, TOP_BANNER_ID);
    setDismissed(TOP_BANNER_ID);
  };

  return (
    <div className={`${classes.topBanner} ${showBanner ? '' : classes.hidden}`}>
      <div className={classes.topBannerText}>{t('topBanner.text')}</div>
      <div className={classes.topBannerButtons}>
        <a
          href={SURVEY_LINKS[GAME_NAME][language]}
          target="_blank"
          rel="noreferrer"
          className="button small"
          onClick={handleActionClick}
        >
          <Icons.ExternalLink />
          {t('topBanner.action')}
        </a>
        <button
          type="button"
          className="button small subtle"
          onClick={handleCloseClick}
        >
          {t('topBanner.close')}
        </button>
      </div>
    </div>
  );
}

export default TopBanner;
